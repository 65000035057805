<template>
  <div>
    <headers />
    <div class="mb row" style="padding:15px;padding-top: 70px !important;">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">{{$language('物件一覧')}}</h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-box">
          <list-table :viewParams="viewParams" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from '@/components/ListTable.vue'
import Headers from '@/components/Headers.vue'

export default {
  name: 'CorpList',
  components: {
    ListTable,
    Headers
  },
  data() {
    return {
      viewParams: {
        name: 'build2',
        apiUrl: '/v1/properties2/',
        articleView: 'BuildArticle2',
        articleParamId: 'BuildingID'
      }
    }
  },mounted() {
   
        localStorage.setItem('homeindex', 'build2');
   }
}
</script>